import Axios from 'axios';

let api = Axios;

const getCards = async () => {
  try {
    const response = await api.get('/payment-cards');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getCardById = async (id) => {
  try {
    const response = await api.get('/payment-cards/' + id);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getUserDefaultCard = async () => {
  try {
    const response = await api.get('/default-payment-card');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeCard = async (data) => {
  console.log(data);
  try {
    const response = await api.post('/payment-cards', data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const setDefaultCard = async (data) => {
  try {
    const response = await api.post(`/payment-cards/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const deleteCard = async (data) => {
  try {
    const response = await api.delete(`/payment-cards/${data.id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const init = async (axiosApi) => {
  api = axiosApi;
};

const CardService = {
  init,
  getCards,
  storeCard,
  getCardById,
  getUserDefaultCard,
  setDefaultCard,
  deleteCard,
};

export default CardService;
