import { useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import { getConfig } from 'config';
import { Context } from './Store';

import { useAuth0 } from '@auth0/auth0-react';
import types from './Reducer/types';

export default () => {
  const { getAccessTokenSilently } = useAuth0();
  const { apiOrigin = 'http://localhost:3001' } = getConfig();
  const api = useRef(
    axios.create({
      baseURL: `${apiOrigin}/api/`,
    }),
  );
  const { state, dispatch } = useContext(Context);

  useEffect(() => {
    const currentAPI = api.current;
    const requestInterceptorId = currentAPI.interceptors.request.use(async (config) => {
      const token = await getAccessTokenSilently();
      config.headers.authorization = `Bearer ${token}`;
      config.cancelToken = axios.CancelToken.source().token;
      if (state.apiToken === token) {
        return config;
      }
      try {
        dispatch({
          type: types.SET_API_TOKEN,
          payload: token,
        });
        await axios.get(`${apiOrigin}/api/status`, {
          headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
      } catch (e) {
        console.log(e);
      }
      return config;
    });
    return () => {
      currentAPI.interceptors.request.eject(requestInterceptorId);
    };
  });
  return api.current;
};
