import Axios from 'axios';
import {convertApiError} from "../utils/Utils"

let api = Axios;

const init = async (axiosApi) => {
  api = axiosApi;
};

const sendUserMessage = async (message) => {
  try {
    const response = await api.post('/admin-notifications/message', { message });
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const AdminNotificationService = {
  init,
  sendUserMessage,
};

export default AdminNotificationService;
