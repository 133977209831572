import React from 'react';
// react component for creating beautiful carousel
import Carousel from 'react-slick';
import makeStyles from '@mui/styles/makeStyles';
// @mui/icons-material
import LocationOn from '@mui/icons-material/LocationOn';
// core components
import GridContainer from 'components/Grid/GridContainer.js';
import GridItem from 'components/Grid/GridItem.js';
import Card from 'components/Card/Card.js';

import PropTypes from 'prop-types';

import styles from 'assets/jss/material-kit-react/views/componentsSections/carouselStyle.js';

const useStyles = makeStyles(styles);

export default function SectionCarousel({ slides = [] }) {
  const classes = useStyles();
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
  };
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={8} className={classes.marginAuto}>
            <Card carousel>
              <Carousel {...settings}>
                {slides.map((slide) => {
                  return (
                    <div key={slide.title}>
                      <img src={slide.image} alt={slide.title} className='slick-image' />
                      <div className='slick-caption'>
                        <h4>
                          <LocationOn />
                          {slide.location}
                        </h4>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}

SectionCarousel.defaultProps = {
  slides: [],
};
SectionCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    }),
  ),
};
