/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@mui/styles/makeStyles';

// core components
import GridItem from 'components/Grid/GridItem.js';
import 'react-credit-cards-2/es/styles-compiled.css';
import Loading from 'components/Loading';
import CreditCardDetail from 'components/CardInputs/CreditCardDetail';
const useStyles = makeStyles((theme) => ({
  // ...styles,
  root: {
    width: '100%',
    maxWidth: 500,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    color: 'red',
    marginTop: 40,
    padding: 25,
    borderRadius: 15,
  },
  child: {
    width: '100%',
    // maxWidth: 500,
    // backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    // maxHeight: 300,
    color: 'red',
    // marginTop: 40,
    padding: 20,
    borderRadius: 15,
  },
  tabStyle: { color: '#324152', marginTop: 20 },
}));
// const CARD_OPTIONS = {
//   iconStyle: "solid",
//   style: {
//     base: {
//       // fontSize,
//       color: "#424770",
//       letterSpacing: "0.025em",
//       fontFamily: "Source Code Pro, monospace",
//       "::placeholder": {
//         color: "#aab7c4",
//       },
//       // padding,
//     },
//     invalid: {
//       color: "#9e2146",
//     },
//   },
// };
const ErrorMessage = ({ children }) => (
  <div className='ErrorMessage' role='alert' style={{ color: 'red' }}>
    {children}
  </div>
);
// const CardField = ({ onChange }) => (
//   <div className="FormRow">
//     <CardElement onChange={onChange} options={CARD_OPTIONS} />
//   </div>
// );
function CardFormStripe({ id = null }) {
  const classes = useStyles();
  // const options = useOptions();

  const [isLoading] = React.useState(true);

  const [error] = React.useState(null);
  const [cardComplete] = React.useState(false);

  if (id && isLoading) {
    return <Loading />;
  }

  return (
    <>
      <>
        <GridItem xs={12} sm={12} md={8} p={100} align='center' className={classes.root}>
          <CreditCardDetail />
        </GridItem>
        <GridItem xs={12} sm={12} md={8} align='center'>
          {error && <ErrorMessage>{error.message}</ErrorMessage>}
        </GridItem>
        {cardComplete}
      </>
    </>
  );
}

CardFormStripe.propTypes = {
  id: PropTypes.string,
  isReadable: PropTypes.bool,
};

CardFormStripe.defaultProps = {
  id: null,
  isReadable: false,
};

export default CardFormStripe;
