import Axios from 'axios';
import {convertApiError} from "../utils/Utils"

let api = Axios;
const init = async (axiosApi) => {
  api = axiosApi;
};

const getCarouselsForLocation = async (locationId) => {
  try {
    const response = await api.get(`/carousels/${locationId}/list-by-location`);
    return response.data.map((carousel) => {
      return {
        ...carousel,
        key: carousel.id,
      }
    });
  } catch (error) {
    throw convertApiError(error);
  }
};

const getCarouselByToken = async (token) => {
  try {
    const response = await api.get(`/carousels/${token}`);
    return response.data;
  } catch (error) {
    throw convertApiError(error);
  }
};

const CarouselService = {
  init,
  getCarouselsForLocation,
  getCarouselByToken,
};

export default CarouselService;
