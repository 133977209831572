export function convertNullsToEmptyStrings(obj) {
  Object.keys(obj).forEach((key) => {
    obj[key] = obj[key] ? obj[key] : '';
  });
}

export function convertApiError(error) {
  console.log('API error', error);
  let errMsg = error?.response?.data?.error;
  if (errMsg) {
    if (errMsg.startsWith('Error: ')) {
      errMsg = errMsg.substring(7);
    }
    return errMsg;
  }
  return error;

}
