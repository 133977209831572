import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import {
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Button,
} from '@mui/material';
import NotificationService from '../../services/NotificationService';
import useAPI from 'useAPI';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import mixpanel from "mixpanel-browser"

React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function NotificationSettings() {
  let navigate = useNavigate();
  const api = useAPI();
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState({
    push_notification: true,
    app_notification: true,
    sms: true,
  });

  const handleChange = async (prop, checked) => {
    setValues({ ...values, [prop]: checked });
  };

  const fetchData = async () => {
    await NotificationService.getNotificationStatus().then((res) => {
      if (res) {
        const { push_notification, app_notification, sms } = res;
        setValues({ push_notification, app_notification, sms });
      }
    });
  };

  React.useEffect(() => {
    NotificationService.init(api);
    fetchData();
    mixpanel.track('NOTIFICATION_SETTINGS');
  }, [api]);

  const handleSave = async () => {
    mixpanel.track('CHANGE_NOTIFICATION_SETTINGS');
    NotificationService.updateNotificationStatus(values).then(() => {
      setOpen(true);
      if (location?.state?.isRedirectFromProfile) {
        navigate('/');
      }
    });
  };

  const handleSkip = async () => {
    mixpanel.track('SKIP_NOTIFICATION_SETTINGS');
    if (location?.state?.isRedirectFromProfile) {
      navigate('/');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginBottom: '3%' }}>
        <Typography
          variant='h6'
          style={{
            padding: 15,
            color: 'black',
          }}
        >
          Notification Settings
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginBottom: '3%' }}>
        <div style={{ color: 'black' }}>
          {' '}
          <h5>Choose Notifications you want to receive</h5>
        </div>
      </GridItem>
      <GridItem xs={12} sm={12} md={12} align='center'>
        <FormControl component='fieldset' variant='standard'>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={!!values.app_notification}
                  onChange={(event) => {
                    handleChange('app_notification', event.target.checked);
                  }}
                  name='app_notification'
                  color='primary'
                />
              }
              label='App Notifications'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!!values.push_notification}
                  onChange={(event) => {
                    handleChange('push_notification', event.target.checked);
                  }}
                  name='push_notification'
                  color='primary'
                />
              }
              label='Push Notifications'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={!!values.sms}
                  onChange={(event) => {
                    handleChange('sms', event.target.checked);
                  }}
                  name='sms'
                  color='primary'
                />
              }
              label='SMS'
            />
          </FormGroup>
          {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
      </GridItem>
      {location?.state?.isRedirectFromProfile && (
        <>
          <GridItem
            xs={2}
            sm={2}
            md={1}
            align='center'
            style={{ marginTop: '5%', marginRight: '2%' }}
          >
            <Button
              onClick={() => handleSkip()}
              variant='contained'
              fullWidth
            >
              Skip
            </Button>
          </GridItem>
        </>
      )}

      <GridItem xs={12} sm={12} md={8} align='center' style={{ marginTop: '5%' }}>
        <Button onClick={() => handleSave()} variant='contained' color='primary' fullWidth>
          Apply Changes
        </Button>
      </GridItem>
      <div>
        <Snackbar
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity='success' onClose={handleClose}>
            Notification Settings Updated
          </Alert>
        </Snackbar>
      </div>
    </AuthenticatedContainer>
  );
}

export default NotificationSettings;
