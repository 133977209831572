import React, { createContext, useReducer } from 'react';
import Reducer from 'Reducer';
import defaultState from './defaultState';

const localState = JSON.parse(localStorage.getItem('localState'));

export const Context = createContext(localState || defaultState);

// eslint-disable-next-line react/prop-types
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, localState || defaultState);

  React.useEffect(() => {
    localStorage.setItem('localState', JSON.stringify(state));
  }, [state]);

  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default Store;
