import Axios from 'axios';

const requests = [];
const { CancelToken } = Axios;
let api = Axios;

const getNotifications = async () => {
  try {
    let cancel;
    const response = await api.get('/notifications', {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getNotificationStatus = async () => {
  try {
    let cancel;
    const response = await api.get('/notifications/status', {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const updateNotificationStatus = async (data) => {
  try {
    let cancel;
    const response = await api.post('/notifications/status', data, {
      cancelToken: new CancelToken((c) => {
        cancel = c;
        requests.push(cancel);
      }),
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
const init = async (axiosApi) => {
  api = axiosApi;
};

const NotificationService = {
  init,
  getNotifications,
  getNotificationStatus,
  updateNotificationStatus,
};

export default NotificationService;
