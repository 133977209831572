/* eslint-disable react/display-name */

import React from 'react';
import { Context } from 'Store';
import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import { Container, Row, Col } from 'reactstrap';
import FormControl from '@mui/material/FormControl';
import makeStyles from '@mui/styles/makeStyles';
import { useAuth0 } from '@auth0/auth0-react';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
} from '@mui/material';
import useAPI from 'useAPI';
import UserService from '../services/UserService';
import OtpService from '../services/OtpService';
import FormattedInput from 'components/Pattern/FormattedInput';
import { useLocation } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import MuiAlert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react18-input-otp';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined';
import types from "../Reducer/types"
import mixpanel from "mixpanel-browser"

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles(() => ({
  ...styles,
  wrapper: {
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

export const ProfileComponent = () => {
  let navigate = useNavigate();
  const classes = useStyles();
  const { user } = useAuth0();
  const location = useLocation();
  const states = require('./Settings/States');

  const { state, dispatch } = React.useContext(Context);
  const { tncChange } = state;

  const [saving, setSaving] = React.useState(false);
  const [systemUser, setSystemUser] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openOtp, setOpenOtp] = React.useState(false);
  const [otp, setOtp] = React.useState('');

  const api = useAPI();

  const [values, setValues] = React.useState({});
  const [errors, setErrors] = React.useState({});

  const verifyOtp = () => {
    mixpanel.track('VERIFY_OTP');
    OtpService.verifyOtp(otp).then((res) => {
      if (res === true) {
        // verified
        fetchUser();
        setOtp('');
        setOpenOtp(false);
        if (location?.state?.isRedirectFromHome) {
          setTimeout(() => {
            navigate('/notifications-settings', {
              state: { isRedirectFromProfile: true },
            });
          }, 1000);
        }
      } else {
        setErrors({ ...errors, otp: 'Please Enter Valid OTP' });
      }
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseOtpDialog = () => {
    mixpanel.track('CANCEL_OTP');
    setOpenOtp(false);
  };

  const fetchUser = async () => {
    const sysUser = await UserService.getUser();
    setValues({ ...sysUser });
    setSystemUser(sysUser);
  };

  React.useEffect(() => {
    UserService.init(api);
    OtpService.init(api);
    fetchUser();
    mixpanel.track('PROFILE');
  }, [api, tncChange]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleOtp = (otp) => {
    setOtp(otp);
  };

  const handleSave = async () => {
    mixpanel.track('SAVE_PROFILE');
    setErrors({});
    ['phone', 'name'].forEach((field) => {
      if (values[field] === '') {
        setErrors({ ...errors, [field]: 'Required' });
      }
    });

    if (Object.keys(errors).length > 0) return;

    setSaving(true);
    try {
      const updatedUser = await UserService.updateUser(values);
      dispatch({
        type: types.SET_USER,
        payload: updatedUser,
      });
      setValues({ ...updatedUser });
      setSystemUser(updatedUser);

      // if phone is already verified & not changed then no need to open otp dialog box.
      if (updatedUser.otpSent) {
        setOpenOtp(true);
      } else {
        setTimeout(() => {
          if (location?.state?.isRedirectFromHome) {
            setTimeout(() => {
              navigate('/notifications-settings', {
                state: { isRedirectFromProfile: true },
              });
            }, 1000);
          } else {
            // redirect to home
            navigate('/');
          }
        }, 1000);
      }
      setOpen(true);
      setSaving(false);
    } catch (e) {
      console.error(e);
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.includes('is not a valid phone number')
      ) {
        setErrors({ ...errors, phone: 'Invalid Phone Number' });
      }
      setSaving(false);
    }
  };

  if (!systemUser) {
    return (
      <AuthenticatedContainer>
        <Container className='m-5'>
          <Row className='align-items-center profile-header mb-5 text-center text-md-left'>
            <Col md={2}>
              <img
                src={user.picture}
                alt='Profile'
                className='rounded-circle img-fluid profile-picture mb-3 mb-md-0'
              />
            </Col>
            <Col>
              <p className='lead text-muted'>{user.email}</p>
            </Col>
          </Row>
        </Container>
      </AuthenticatedContainer>
    );
  }

  return (
    <AuthenticatedContainer>
      <Container className='m-5'>
        <Row>
          <Dialog
            classes={{
              root: classes.center,
              paper: classes.modal,
            }}
            open={openOtp}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseOtpDialog}
            aria-labelledby='modal-slide-title'
            aria-describedby='modal-slide-description'
          >
            <DialogTitle
              id='classic-modal-slide-title'
              className={classes.modalHeader}
              component='div'
            >
              <h4>Enter OTP</h4>
              <h5>Please Verify Your Phone Number {values['phone']}</h5>
              <h6 style={{ color: 'red' }}>{errors.otp}</h6>
            </DialogTitle>
            <DialogContent id='modal-slide-description' sx={{ justifyContent: 'center' }}>
              <OtpInput
                value={otp}
                onChange={handleOtp}
                numInputs={4}
                separator={<span>-</span>}
                inputStyle={{
                  width: '30px',
                  height: '30px',
                  margin: '15px',
                  fontSize: '15px',
                  borderRadius: 4,
                  border: '1px solid rgba(0,0,0,0.3)',
                }}
                isInputNum={true}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOtpDialog}>Cancel</Button>
              <Button onClick={verifyOtp} color='primary'>
                Verify
              </Button>
            </DialogActions>
          </Dialog>
        </Row>
        <Row className='align-items-center profile-header mb-5 text-center text-md-left'>
          <Col md={2}>
            <img
              src={user.picture}
              alt='Profile'
              className='rounded-circle img-fluid profile-picture mb-3 mb-md-0'
            />
          </Col>
          <Col>
            <p className='lead text-muted'>{user.email}</p>
          </Col>
        </Row>
        <Row>
          <Box pb={5}>
            <TextField
              autoFocus
              margin='dense'
              id='name'
              label='Name*'
              value={values.name}
              onChange={handleChange('name')}
              type='text'
              fullWidth
              error={!!errors.name}
            />
            <TextField
              autoFocus
              margin='dense'
              id='address'
              label='Address'
              value={values.address}
              onChange={handleChange('address')}
              type='text'
              fullWidth
              multiline
              rows={1}
            />

            <TextField
              autoFocus
              margin='dense'
              id='city'
              label='City'
              value={values.city}
              onChange={handleChange('city')}
              type='text'
              fullWidth
            />

            <FormControl fullWidth margin='dense' className={classes.formControl}>
              <InputLabel id='state-id'>State</InputLabel>
              <Select label='State' value={values.state} required onChange={handleChange('state')}>
                <MenuItem
                  disabled
                  value=''
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                >
                  Choose State
                </MenuItem>

                {states &&
                  states.map((state) => (
                    <MenuItem
                      value={`${state}`}
                      key={`${state}`}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                    >
                      {`${state}`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormattedInput
              name='zipcode'
              format='#####'
              mask=' '
              label='Zip Code'
              value={values.zipcode}
              onChange={handleChange('zipcode')}
            />

            <TextField
              error={!!errors.phone}
              margin='dense'
              id='phone'
              label='Phone*'
              value={values.phone}
              onChange={handleChange('phone')}
              fullWidth
              type='tel'
              InputProps={{
                endAdornment: values.phone_verified ? (
                  <CheckCircleIcon style={{ color: '#006600' }} />
                ) : (
                  <Tooltip title='Unverified Phone Number' aria-label='add'>
                    <IconButton size='large'>
                      {' '}
                      <ErrorOutlinedIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Tooltip>
                ),
              }}
            />

            <div className={classes.wrapper}>
              <Button
                onClick={handleSave}
                variant='contained'
                disabled={saving}
                color='primary'
                fullWidth
                className='mt-3'
              >
                Save
              </Button>
              {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
            </div>
            <div>
              <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <Alert severity='success' onClose={handleClose}>
                  Profile Updated Successfully
                </Alert>
              </Snackbar>
            </div>
          </Box>
        </Row>
      </Container>
    </AuthenticatedContainer>
  );
};

export default ProfileComponent;
