import React, { useState } from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Typography, Button, Card, CardContent } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import VehicleService from '../../services/VehicleService';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';

import useAPI from 'useAPI';
import { CheckRounded } from '@mui/icons-material';

// import Autocomplete from "@mui/lab/Autocomplete";

// const currentYear = new Date().getFullYear();

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    flexBasis: 'auto',
  },
  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function Vehicles() {
  const classes = useStyles();
  const api = useAPI();

  // const [vehicles, setVehicles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const color = [
    { name: 'Orange' },
    { name: 'Green' },
    { name: 'Blue' },
    { name: 'Red' },
    { name: 'Black' },
  ];
  const [oldValues, setOldValues] = React.useState({
    make: '',
    model: '',
    year: '',
    state: '',
    plate: '',
    description: '',
    type: '',
    color: '',
    zip: '',
  });
  const initialValues = {
    make: '',
    model: '',
    year: '',
    state: '',
    plate: '',
    description: '',
    type: '',
    color: '',
    zip: '',
  };
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState(initialValues);
  // const [vehicleLabel, setvehicleLabel] = React.useState("You have unsaved changes");
  const [vehicleLabel, setvehicleLabel] = React.useState({
    label: 'You have unsaved changes',
    success: false,
    color: '#FC9F0D',
  });

  const handleError = (prop) => (value) => {
    setErrors({ ...errors, [prop]: value });
  };

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  React.useEffect(() => {
    if (
      JSON.stringify(oldValues) !== JSON.stringify(values) &&
      JSON.stringify(oldValues) !== JSON.stringify(initialValues)
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [values, oldValues]);

  const handleSave = () => {
    saveVehicle();
  };

  const handleSavePin = () => {
    console.log('handleSavePin');
  };

  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  // const verifyNumber = (value) => {
  //   var numberRex = new RegExp("^[0-9]+$");
  //   if (numberRex.test(value)) {
  //     return true;
  //   }
  //   return false;
  // };

  const saveVehicle = async () => {
    let error = 0;
    ['make', 'model', 'plate', 'color', 'zip'].forEach((field) => {
      if (values[field] === '') {
        handleError(field, 'error');
        error = 1;
      }
    });
    if (error) return;
    setSaving(true);
    setvehicleLabel({
      label: 'Changes saved!',
      success: true,
      color: 'green',
    });
    setTimeout(() => {
      setvehicleLabel({
        label: 'You have unsaved changes',
        success: false,
        color: '#FC9F0D',
      });
    }, 5000);
    await VehicleService.storeVehicle({
      id: values.id,
      user_id: values.user_id,
      color: values.color,
      zip: values.zip,
      make: values.make,
      model: values.model,
      year: values.year,
      state: values.state,
      plate: values.plate,
      description: values.description,
      type: values.type,
    });
    fetchuservehicle();
    setSaving(false);
  };

  const fetchuservehicle = async () => {
    await VehicleService.getVehicles().then((res) => {
      setValues(res[0]);
      setOldValues(res[0]);
    });
  };

  // var range = (l, r) => new Array(r - l + 1).fill().map((_, k) => k + l);

  React.useEffect(() => {
    VehicleService.init(api);
    fetchuservehicle();
  }, [api]);
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          Vehicle Details
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography
          variant='body1'
          style={{
            padding: 15,
            color: `${vehicleLabel.color}`,
          }}
        >
          {vehicleLabel.success || isChanged ? (
            <>
              {(vehicleLabel.success && <CheckRounded />) || <WarningIcon />}

              {vehicleLabel.label}
            </>
          ) : null}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Box pb={5} className={classes.inputLabel}>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Make:
            </Grid>
            <Grid item xs={9} sm={9}>
              <TextField
                error={errors.make === 'error'}
                autoFocus
                margin='dense'
                id='plate'
                value={values.make}
                type='text'
                fullWidth
                onChange={handleChange('make')}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Model:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                error={errors.model === 'error'}
                autoFocus
                margin='dense'
                id='plate'
                value={values.model}
                type='text'
                fullWidth
                onChange={handleChange('model')}
              />
            </Grid>
          </Grid>

          {/* <Grid
            container
            direction="row"
            alignItems="center"
            item
            xs={12}
            sm={12}
            md={12}
            align="center"
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Year:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              {values.year && (
                <FormControl fullWidth  margin="dense">
                  <Select
                    value={values.year}
                    error={errors.year === "error"}
                    onChange={handleChange("year")}
                    style={{ textAlign: "left" }}
                  >
                    {range(1900, currentYear).map((year) => (
                      <MenuItem key={`${year}`} value={year}>
                        {`${year}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid> */}

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Plate #:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                autoFocus
                error={errors.plate === 'error'}
                margin='dense'
                id='plate'
                value={values.plate}
                type='text'
                fullWidth
                onChange={handleChange('plate')}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Color:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              {values.color && (
                <FormControl fullWidth margin='dense'>
                  <Select
                    value={values.color}
                    onChange={handleChange('color')}
                    error={errors.color === 'error'}
                    style={{ textAlign: 'left' }}
                  >
                    {color.map((color) => (
                      <MenuItem key={`${color.name}`} value={`${color.name}`}>
                        {`${color.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Zip:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                autoFocus
                margin='dense'
                id='plate'
                error={errors.zip === 'error'}
                value={values.zip}
                type='number'
                fullWidth
                onChange={handleChange('zip')}
              />
            </Grid>
          </Grid>

          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
            fullWidth
            style={{
              marginTop: 20,
              color: 'white',
              background: `${vehicleLabel.color}`,
            }}
          >
            Save Changes
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
        </Box>
      </GridItem>
      <GridItem
        xs={12}
        sm={12}
        md={8}
        item
        align='center'
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Card
          xs={12}
          sm={12}
          align='center'
          style={{
            borderTopLeftRadius: 20,
            borderTopRightRadius: 20,
            marginBottom: 40,
          }}
        >
          <CardContent>
            <Typography>
              Your PIN is your unique access code to park and retrieve your vehicle. We generate one
              automatically, but you can choose your own. It must contain the following: A-Z, 0-9,
              no special characters, underscores or punctuation.
            </Typography>
            {/* <TextField
              autoFocus
              margin="dense"
              id="pin"
              label="PIN:"
              
              onChange={handleChange("pin")}
              type="text"
              fullWidth
            /> */}
            <Grid
              container
              spacing={2}
              direction='row'
              justifyContent='flex-start'
              alignItems='center'
              item
              xs={12}
              sm={12}
              md={12}
              align='center'
            >
              <Grid item xs={2} sm={2} md={2}>
                <b>PIN:</b>
              </Grid>
              <Grid item className={classes.customTextField} xs={10}>
                <TextField
                  autoFocus
                  margin='dense'
                  id='plate'
                  value={values.pin}
                  type='text'
                  fullWidth
                />
              </Grid>
            </Grid>
            <Button
              onClick={handleSavePin}
              color='primary'
              variant='contained'
              fullWidth
              style={{ marginTop: 20, color: 'white' }}
            >
              Save Changes
            </Button>
          </CardContent>
        </Card>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default Vehicles;
