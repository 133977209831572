import React from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Button, TextField, Typography } from '@mui/material';

import useAPI from 'useAPI';
import { Autocomplete } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Context } from 'Store';
import types from 'Reducer/types';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import Loading from 'components/Loading';
import Locations from './Components/Locations';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"
import CardService from "../services/CardService"
import CarouselService from 'services/CarouselService';
import ParkingSessionService from '../services/ParkingSessionService';
import VehicleService from '../services/VehicleService';
import mixpanel from "mixpanel-browser"
import UserService from "../services/UserService"

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  newButton: { marginTop: 20, color: 'white' },
  center: {textAlign: 'center', alignContent: 'center'},
  dialogTitle: {lineHeight: '1.2em'},
}));

function getDefaultOfFirst(values) {
  return values.find((element) => element.is_default) || values[0];
}

function SelectVehicle() {
  const navigate = useNavigate();
  const classes = useStyles();
  const api = useAPI();
  const [isFromPark, setIsFromPark] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const location = useLocation();
  const [carousels, setCarousels] = React.useState([]);
  const { state, dispatch } = React.useContext(Context);
  const { userLocation, vehicles = [], parkingSession } = state;
  const [selectedVehicle, setSelectedVehicle] = React.useState(vehicles.length > 0 ? getDefaultOfFirst(vehicles) : '');
  const [isCarouselAvailable, setIsCarouselAvailable] = React.useState(false);
  const [isScanQR, setScanQR] = React.useState(false);
  const [parkingToken, setParkingToken] = React.useState('');
  const [isShowParkingToken, setShowParkingToken] = React.useState(false);

  React.useEffect(() => {
    VehicleService.init(api);
    CarouselService.init(api);
    ParkingSessionService.init(api);
    CardService.init(api);
    UserService.init(api);
    if (location.pathname.includes('park')) {
      setIsFromPark(true);
      if (ParkingSessionService.isParked(parkingSession) || !userLocation) {
        navigate('/');
        return;
      }
    }
    CardService.getUserDefaultCard().then((card) => {
      if (!card) {
        UserService.getUser().then((user) => {
          dispatch({ type: types.SET_USER, payload: user });
          if (!user.is_free_parking && location.pathname.includes('park')) {
            navigate('/park/card/new');
          }
        })
      }
    })
    fetchVehicles();
    fetchCarousels();
  }, [api]);

  React.useEffect(() => {
    setIsCarouselAvailable(carousels.filter((c) => c.available_spaces > 0).length > 0);
  }, [carousels])

  const fetchCarousels = async () => {
    const carousels = await CarouselService.getCarouselsForLocation(userLocation.id);
    setCarousels(carousels);
  };

  const fetchVehicles = async () => {
    const res = await VehicleService.getVehicles();
    dispatch({ type: types.SET_VEHICLES, payload: res });
    if (res.length > 0) {
      setSelectedVehicle(getDefaultOfFirst(res));
    } else {
      const newPath = location.pathname.includes('park') ? '/park/vehicle/new' : '/vehicles/new';
      navigate(newPath);
      return;
    }
    setIsLoading(false);
  };

  const onNewVehicle = () => {
    mixpanel.track('ADD_VEHICLE');
    if (isFromPark) {
      navigate('/park/vehicle/new');
    } else {
      navigate('/vehicles/new');
    }
  };

  const onScanQR = () => {
    if (selectedVehicle) {
      if (userLocation.hasQRReaders) {
        ParkingSessionService.getParkingToken(selectedVehicle.id, 'PARK').then((token) => {
          mixpanel.track('SHOW_QR_TO_PARK');
          setParkingToken(token);
          setShowParkingToken(true);
        });
      } else {
        mixpanel.track('OPEN_QR_TO_PARK');
        setScanQR(true);
      }
    }
  };

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  };

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then((status) => {
        if (status) {
          navigate('/');
        }
      })
      .catch((e) => {
        alert(e);
        onCancelShowParkingToken();
      });
  };

  React.useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  const handleQRScanned = (token) => {
    mixpanel.track('SCAN_QR_TO_PARK');
    setScanQR(false);
    ParkingSessionService.getCarouselSlot(token, userLocation.id, selectedVehicle.id).then(() => {
      navigate('/');
    }).catch((err) => {
      alert(err);
    });
  }

  const onEditVehicle = () => {
    if (selectedVehicle) {
      mixpanel.track('EDIT_VEHICLE');
      navigate('/vehicle/' + selectedVehicle.id + '/edit');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <AuthenticatedContainer>
      <Locations showMenu={false} />
      <GridItem xs={12} sm={12} md={8} align='center' style={{ padding: 10 }}>
        <Typography variant='body1' color='primary'>
          {isFromPark ? 'Select OR Add Vehicle To Park' : 'Manage Vehicle'}
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'></GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={3}>
          <Autocomplete
            id='combo-box-select-vehicle'
            options={vehicles}
            getOptionLabel={(option) => option.title || ''}
            style={{ width: 300, textAlign: 'center' }}
            value={selectedVehicle}
            onChange={(event, newValue) => {
              setSelectedVehicle(newValue);
            }}
            renderInput={(params) => <TextField {...params} label='Select Vehicle' />}
          />
        </Box>
        {isFromPark && (
          <>
            <Typography variant='subtitle1' color='primary'>
              {userLocation.name}
            </Typography>
            <Box p={1}>
              {carousels && carousels.length > 0 ? (
                carousels.map((carousel) => {
                  return <div key={carousel.id}>
                    <GridItem xs={12} sm={12} md={8} align='center' style={{ fontSize: '10pt', color: 'black' }}>
                      {carousel.nickname} : {carousel.available_spaces === 0 ? 'No spaces available' : carousel.available_spaces === 1 ? '1 space left' : `${carousel.available_spaces} spaces available`}
                    </GridItem>
                  </div>
                })
              ) : (
                <Typography variant='body1' color='primary'>
                  No carousels at selected location
                </Typography>
              )}
            </Box>
          </>)}
        <Box pd={3} px={3}>
          {isFromPark && isCarouselAvailable ? isScanQR ?
            <ScanQRDialog classes={classes} isOpen={isScanQR} title="Scan QR Code" onScan={handleQRScanned} onCancel={()=>setScanQR(false)} /> :
            (isShowParkingToken ? <ShowQRDialog classes={classes} isOpen={isShowParkingToken} title={'Hold QR code up to reader at the carousel'} code={parkingToken} onCancel={onCancelShowParkingToken} /> :
            <Button
              onClick={onScanQR}
              color='primary'
              variant='contained'
              fullWidth
              className={classes.newButton}
              disabled={!selectedVehicle}
            >
              Drive to Carousel and Scan QR Code
            </Button>
          ) : (
            <Button
              onClick={onEditVehicle}
              color='primary'
              variant='contained'
              fullWidth
              className={classes.newButton}
              disabled={!selectedVehicle}
            >
              Edit Selected Vehicle
            </Button>
          )}
        </Box>
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center' style={{ color: 'black', marginTop: 20 }}>
        OR
      </GridItem>

      <GridItem xs={12} sm={12} md={8} align='center'>
        <Box p={2}>
          <Button
            onClick={onNewVehicle}
            color='primary'
            variant='contained'
            fullWidth
            className={classes.newButton}
          >
            Add New Vehicle
          </Button>
        </Box>
      </GridItem>
    </AuthenticatedContainer>
  );
}

export default SelectVehicle;

