import React from 'react';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Typography } from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import { useParams } from 'react-router-dom';
// import CardForm from "./CardForm";
import CardFormStripe from './CardFormStripe';

function EditCard() {
  let { id } = useParams();
  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          Edit Card
        </Typography>
      </GridItem>
      {/* <CardForm id={id} /> */}
      <CardFormStripe id={id} />
    </AuthenticatedContainer>
  );
}

export default EditCard;
