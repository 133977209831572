/* eslint-disable react/display-name */

import React from 'react';
import Grid from '@mui/material/Grid';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Button } from '@mui/material';
import useAPI from 'useAPI';
import Snackbar from '@mui/material/Snackbar';
import AdminNotificationService from '../services/AdminNotificationService';
import MuiAlert from '@mui/material/Alert';

export const HelpComponent = () => {
  const api = useAPI();
  const [message, setMessage] = React.useState('');
  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState('');
  const [alertSeverity, setAlertSeverity] = React.useState('');

  React.useEffect(() => {
    AdminNotificationService.init(api);
  }, [api]);

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleSend = async () => {
    try {
      await AdminNotificationService.sendUserMessage(message);
      setMessage('');
      setAlertMessage('Message sent');
      setAlertSeverity('success');
    } catch (e) {
      setAlertMessage(e.message || 'Error sending message. Please call the phone number.');
      setAlertSeverity('error');
    }
    setShowAlert(true);
  };

  return (
    <Grid container direction='row' justifyContent='center' alignItems='center' spacing={2}>
      <Grid item xs={11}>
        For 24/7 support please call 800-555-1212.
      </Grid>
      <Grid item xs={11}>
        You can also send us an instant message here:
      </Grid>
      <Grid item xs={11}>
        <TextareaAutosize
          id='message'
          label='message'
          cols={40}
          minRows={10}
          style={{ width: '80%', maxWidth: 350 }}
          aria-label='message'
          placeholder='Message'
          value={message}
          onChange={(event) => setMessage(event?.target?.value)}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={handleSend}
          variant='contained'
          disabled={!message || message.length === 0}
          color='primary'
        >
          Send
        </Button>
      </Grid>
      <Grid item xs={11}>
        <Snackbar
          open={showAlert}
          autoHideDuration={3000}
          onClose={handleAlertClose}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <Alert severity={alertSeverity} onClose={handleAlertClose}>
            {alertMessage}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default HelpComponent;
