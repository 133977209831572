import * as React from 'react';
import { PatternFormat } from 'react-number-format';
import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';

function FormattedInput(props) {
  const { name, format, mask, label, value, onChange, disabled } = props;
  const materialUITextFieldProps = {
    id: name,
    label: label,
    fullWidth: true,
    margin: 'dense',
    type: 'text',
    disabled: !!disabled,
  };

  return (
    <Box
      sx={{
        '& > :not(style)': {
          mt: 1,
        },
      }}
    >
      <PatternFormat
        customInput={TextField}
        disabled={disabled}
        {...materialUITextFieldProps}
        format={format}
        mask={mask}
        defaultValue={value}
        value={value}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    </Box>
  );
}

FormattedInput.propTypes = {
  name: PropTypes.string.isRequired,
  format: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

FormattedInput.displayName = 'FormattedInput';
export default FormattedInput;
