import React from 'react';

// core components
import GridItem from 'components/Grid/GridItem.js';
import {Box, Button, Divider} from '@mui/material';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import HomeScreenWithoutLogin from 'components/HomeScreenWithoutLogin';
import car from '../assets/img/car.svg';
import parkIcon from '../assets/img/svg/park_icon.svg';
import retrieveIcon from '../assets/img/svg/retrieve_icon.svg';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import UserService from '../services/UserService';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import {useNavigate} from 'react-router-dom';
import {Context} from 'Store';
import types from 'Reducer/types';
import useAPI from 'useAPI';
import moment from 'moment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Paper from '@mui/material/Paper';
import TermsOfUseContent from './Components/TermsOfUseContent';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {useStripe} from '@stripe/react-stripe-js';
import RefreshIcon from '@mui/icons-material/Refresh';
import Locations from './Components/Locations';
import ParkingSessionService from '../services/ParkingSessionService';

import LinearProgress from '@mui/material/LinearProgress';
import Countdown from 'react-countdown';
import PropTypes from 'prop-types';
import {useConfirm} from "material-ui-confirm";
import mixpanel from 'mixpanel-browser';
import ScanQRDialog from './Components/ScanQRDialog';
import ShowQRDialog from "./Components/ShowQRDialog"

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    borderRadius: 10,
    marginTop: 10,
    marginBottom: 10,
    height: 100,
    width: '100%',
    cursor: 'pointer',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  cover: {
    width: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  coverParked: {
    width: 120,
    display: 'flex',
    paddingTop: 20,
    justifyContent: 'center',
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  cardText: {
    font: 'normal normal 900 22px/30px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallCardText: {
    font: 'normal normal 900 22px/30px Lato',
    letterSpacing: '0px',
    textAlign: 'left',
    opacity: '1',
  },
  smallNote: {
    fontSize: '10pt',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: '1.2em',
    paddingTop: 20,
  },
  center: {textAlign: 'center', alignContent: 'center'},
  dialogTitle: {lineHeight: '1.2em'},
}));
export default function Home() {
  let navigate = useNavigate();
  const classes = useStyles();
  const stripe = useStripe();
  const api = useAPI();
  const { state, dispatch } = React.useContext(Context);
  const { user, userLocation, parkingSession = {} } = state;
  const [parkTime, setParkTime] = React.useState('');
  const [failed, setFailed] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [saving, setSaving] = React.useState(false);
  const confirm = useConfirm();

  /** PARK **/
  const parkingChecklist = [
    'Vehicle is in park',
    'Engine is off',
    'Keys and necessary belongings are out of the vehicle',
    'Antennas are stowed',
    'Car lights are off',
    'Doors, trunk, tailgate are closed',
    'There are no people or pets in the vehicle or parking bay',
  ];

  const [parkingChecklistStep, setParkingChecklistStep] = React.useState(0);
  const handleNextParkingChecklistStep = async () => {
    mixpanel.track(`PARKING_CHECKLIST_STEP_${parkingChecklistStep + 1}`, {session_id: parkingSession.id});
    setParkingChecklistStep((prevStep) => prevStep + 1);
  };
  const [scanQRForStowing, setScanQRForStowing] = React.useState(false);
  const [isShowParkingToken, setShowParkingToken] = React.useState(false);
  const [parkingToken, setParkingToken] = React.useState('');
  const [carouselProgress, setCarouselProgress] = React.useState(0);

  /** RETRIEVE **/
  const [readyToLeave, setReadyToLeave] = React.useState(false);
  const [tokenForLeaving, setTokenForLeaving] = React.useState();
  const [paymentSummary, setPaymentSummary] = React.useState([]);
  const [showConfirmPayment, setShowConfirmPayment] = React.useState(false);
  const [showSubmittingPayment, setShowSubmittingPayment] = React.useState(false);
  function getRetrieveChecklist() {
    return [
      `Your vehicle is ready at ${parkingSession?.carousel?.nickname}`,
      'Be cautious when stepping onto the parking platform',
      'Ensure all passengers are in the car',
      'Close all the doors',
      'Be cautious when removing your vehicle from the parking platform',
    ];
  }
  const retrieveChecklist = getRetrieveChecklist();
  const [showRetrieveChecklist, setShowRetrieveChecklist] = React.useState(false);
  const [retrieveChecklistStep, setRetrieveChecklistStep] = React.useState(0);
  const [showDriveAway, setShowDriveAway] = React.useState(false);
  const handleNextRetrieveChecklistStep = async () => {
    mixpanel.track(`RETRIEVE_CHECKLIST_STEP_${retrieveChecklistStep + 1}`, {session_id: parkingSession.id});
    setRetrieveChecklistStep((prevStep) => prevStep + 1);
  };

  React.useEffect(() => {
    if (retrieveChecklistStep === retrieveChecklist.length) {
      setTimeout(() => {
        setShowRetrieveChecklist(false);
        setShowDriveAway(true);
      }, 2000);
    }
  }, [retrieveChecklistStep]);

  /** REPARK **/
  const [readyToRepark, setReadyToRepark] = React.useState(false);
  const [scanQRForReparkRestowing, setScanQRForReparkRestowing] = React.useState(false);
  const [reparkInProgress] = React.useState(false);
  function getReparkChecklist() {
    return [
      `Your vehicle is ready at ${parkingSession?.carousel?.nickname}`,
      'Be cautious when stepping onto the parking platform',
      'Retrieve your items from the vehicle',
      'Close all doors and trunk',
      'Leave your vehicle on the platform and exit the parking bay',
    ];
  }
  const reparkChecklist = getReparkChecklist();
  const [reparkChecklistStep, setReparkChecklistStep] = React.useState(0);
  const handleNextReparkChecklistStep = async () => {
    mixpanel.track(`REPARK_CHECKLIST_STEP_${reparkChecklistStep + 1}`, {session_id: parkingSession.id});
    setReparkChecklistStep((prevStep) => prevStep + 1);
  };

  // Other dialog control
  const [showTAndC, setShowTAndC] = React.useState(false);
  const [showApplyDiscount, setShowApplyDiscount] = React.useState(false);

  // Discount code field
  const [discountCode, setDiscountCode] = React.useState('');
  const [isDiscountCodeFieldError, setIsDiscountCodeFieldError] = React.useState(false);

  // Snackbar alerts
  const [snackbarError, setSnackbarError] = React.useState('');

  const handleEnterDiscountCode = () => {
    setShowApplyDiscount(true);
    mixpanel.track('OPEN_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleCancelDiscount = () => {
    setShowApplyDiscount(false);
    mixpanel.track('CANCEL_DISCOUNT', {session_id: parkingSession.id});
  }

  const handleApplyDiscountCode = async () => {
    if (discountCode === '') {
      setIsDiscountCodeFieldError(true);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id});
      return;
    }
    try {
      mixpanel.track('APPLY_DISCOUNT', {session_id: parkingSession.id, discount_code: discountCode});
      const updatedSession = await ParkingSessionService.applyDiscountCode(discountCode, parkingSession.id);
      updateSession(updatedSession);
      setShowApplyDiscount(false);
    } catch (e) {
      console.log('Caught error', e);
      mixpanel.track('APPLY_DISCOUNT_ERROR', {session_id: parkingSession.id, err: e.toString()});
      setSnackbarError(e);
    }
  }

  const handleCloseTAndC = async (agreed) => {
    setSaving(true);
    mixpanel.track(agreed ? 'TNC_AGREE' : 'TNC_DISAGREE')
    const updatedUser = await UserService.updateUser({
      ...user,
      is_new: 0,
      is_agree: agreed ? 1 : 0,
    });
    dispatch({
      type: types.SET_USER,
      payload: updatedUser,
    });
    setShowTAndC(false);
    setSaving(false);
  };
  let activeColor = '#008BE8';
  let disabledColor = '#394253';
  let successColor = '#2E933C';
  let mutedColor = '#CACACA';
  let mainColor = activeColor;

  const updateSession = (session) => {
    if (!!session && (!parkingSession || session.status !== parkingSession.status)) {
      mixpanel.track(`SESSION_${session.status}`, {session_id: session.id});
    } else if (!!parkingSession && !session) {
      mixpanel.track('SESSION_END', {session_id: parkingSession.id});
    }
    dispatch({
      type: types.SET_PARKING_SESSION,
      payload: session,
    });
  }

  const refreshSession = () => {
    ParkingSessionService.getCurrentSession()
      .then((session) => updateSession(session))
      .catch((e) => console.error(e));
  };

  const refreshNotificationCount = () => {
    api.get('notifications-count')
      .then((res) => {
         dispatch({
           type: types.SET_NOTIFICATIONS,
           payload: res.data,
         });
       })
       .catch((e) => console.error(e));
  }

  // Page load effects
  React.useEffect(() => {
    UserService.init(api);
    ParkingSessionService.init(api);
    mixpanel.track('HOME');
    if (user?.phone === '') {
      navigate('/profile', { state: { isRedirectFromHome: true } });
    } else if (user?.is_new) {
      setShowTAndC(true);
    }
    refreshSession();
    refreshNotificationCount();
  }, [api]);

  const updateTime = () => {
    var now = moment();
    var then = moment(parkingSession.start_time);
    var ms = moment(now, 'DD/MM/YYYY HH:mm:ss').diff(moment(then, 'DD/MM/YYYY HH:mm:ss'));
    var d = moment.duration(ms);
    setParkTime(Math.floor(d.asHours()) + moment.utc(ms).format(':mm:ss'));
  };

  React.useEffect(() => {
    refreshNotificationCount();
    if (!parkingSession) {
      postRetrieveCleanup();
      return;
    }
    const timersToCleanup = [];
    if (ParkingSessionService.isParked(parkingSession)) {
      setCarouselProgress(0);
      setParkingChecklistStep(0);
      setReparkChecklistStep(0);
      mainColor = disabledColor;
      const updateTimeRef = setInterval(function () {
        updateTime();
      }, 1000);
      timersToCleanup.push(updateTimeRef);
    } else if (ParkingSessionService.isTransitionalState(parkingSession)) {
      const updateSessionRef = setInterval(function () {
        refreshSession();
      }, 2000);
      timersToCleanup.push(updateSessionRef);
    }
    if (ParkingSessionService.isCarouselInUse(parkingSession)
    ) {
      // Takes ~1 minute
      const carouselTimer = setInterval(() => {
        setCarouselProgress((oldProgress) => {
          if (oldProgress === 100) {
            return 100;
          }
          return oldProgress + (100.0/60.0); // denominator is expected number of secs to stow
        });
      }, 1000);
      timersToCleanup.push(carouselTimer);
    }
    if (ParkingSessionService.isRetrievingReadyToLeave(parkingSession) && (retrieveChecklistStep < retrieveChecklist.length)) {
      setShowRetrieveChecklist(true);
    }
    if (ParkingSessionService.isReparkingCarAvailable(parkingSession)) {
      setCarouselProgress(0);
    }
    return () => {
      timersToCleanup.forEach((i) => clearInterval(i));
    }
  }, [parkingSession]);

  const checkParkingTokenStatus = () => {
    ParkingSessionService.getParkingTokenStatus(parkingToken)
      .then((status) => {
        if (status?.confirmed || status?.success || status?.payment?.success) {
          onCancelShowParkingToken();
          refreshSession();
        } else if (status?.totalAmount) { // payment summary
          setTokenForLeaving(parkingToken); // retain parking token to submit with retrieve request
          onCancelShowParkingToken();
          setPaymentSummary(status);
          setShowConfirmPayment(true);
        }
      })
      .catch((e) => {
        onCancelShowParkingToken();
        alert(e);
      });
  };

  React.useEffect(() => {
    let tokenStatusTimer = null;
    if (isShowParkingToken) {
      tokenStatusTimer = setInterval(function () {
        checkParkingTokenStatus();
      }, 2000);
    }
    return () => {
      tokenStatusTimer && clearInterval(tokenStatusTimer);
    }
  }, [isShowParkingToken]);

  const onScanQRForStowing = () => {
    if (ParkingSessionService.isOnPallet(parkingSession)) { // extra check
      if (userLocation.hasQRReaders) {
        ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'STOW', parkingSession.id).then((token) => {
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SHOW_QR_TO_STOW', {session_id: parkingSession.id});
        })
      } else {
        setScanQRForStowing(true);
        mixpanel.track('OPEN_QR_TO_STOW', {session_id: parkingSession.id});
      }
    }
  }

  const onCancelShowParkingToken = () => {
    setParkingToken('');
    setShowParkingToken(false);
  }

  const handleQRScannedForStowing = (token) => {
    setScanQRForStowing(false);
    mixpanel.track('SCAN_QR_TO_STOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForParking(token, parkingSession.id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const handlePark = async () => {
    if (!user.is_agree) {
      setShowTAndC(true);
      mixpanel.track('SHOW_TC', {is_agree: false});
      return;
    }
    if (!!userLocation && userLocation.availableSpaces > 0 && !ParkingSessionService.isParked(parkingSession)) {
      navigate('/park/vehicle/select');
    }
  };

  const handleRefresh = async () => {
    mixpanel.track('REFRESH_SESSION', {session_id: parkingSession?.id});
    await refreshSession();
  };

  const handleGetSomethingFromVehicle = () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (userLocation.hasQRReaders) {
        ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'REPARK', parkingSession.id).then((token) => {
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
        })
      } else {
        mixpanel.track('OPEN_QR_TO_REPARK', {session_id: parkingSession.id});
        setReadyToRepark(true);
      }
    }
  };

  const handleReadyToLeave = () => {
    if (ParkingSessionService.isParked(parkingSession)) { // extra check
      if (userLocation.hasQRReaders) {
        ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'RETRIEVE', parkingSession.id).then((token) => {
          setParkingToken(token);
          setShowParkingToken(true);
          mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
        })
      } else {
        mixpanel.track('OPEN_QR_TO_LEAVE', {session_id: parkingSession.id});
        setReadyToLeave(true);
      }
    }
  };

  const handleQRScannedForLeaving = (token) => {
    setReadyToLeave(false);
    setTokenForLeaving(token);
    mixpanel.track('SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
    ParkingSessionService.retrieve(token, parkingSession.id).then((res) => {
      let success = (res?.success || res?.payment?.success);
      if (success) {
        updateSession(res.session);
      } else if (res?.totalAmount) {
        setPaymentSummary(res);
        setShowConfirmPayment(true);
      } else {
        console.error('unknown response', res);
      }
    }).catch((err) => {
      setTokenForLeaving(null);
      alert(err);
    });
  }

  const cancelScanQRForLeaving = () => {
    setReadyToLeave(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_LEAVE', {session_id: parkingSession.id});
  }

  const handleQRScannedForReparkRetrieval = (token) => {
    setReadyToRepark(false);
    mixpanel.track('SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
    ParkingSessionService.retrieveVehicleForRepark(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    });
  }

  const cancelScanQRForReparkRetrieval = () => {
    setReadyToRepark(false);
    mixpanel.track('CANCEL_SCAN_QR_TO_REPARK', {session_id: parkingSession.id});
  }

  /**
   * Safety check is done and ready to show QR scanner to restow
   */
  const onScanQRForRestowing = () => {
    if (userLocation.hasQRReaders) {
      ParkingSessionService.getParkingToken(parkingSession.vehicle_id, 'STOW', parkingSession.id).then((token) => {
        setParkingToken(token);
        setShowParkingToken(true);
        mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
      })
    } else {
      setScanQRForReparkRestowing(true);
      mixpanel.track('OPEN_QR_TO_RESTOW', {session_id: parkingSession.id});
    }
  }

  const handleQRScannedForReparkRestowing = (token) => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('SCAN_QR_TO_RESTOW', {session_id: parkingSession.id});
    ParkingSessionService.stowVehicleForReparking(token, parkingSession.vehicle_id).then((session) => {
      updateSession(session);
    }).catch((err) => {
      alert(err);
    })
  }

  const cancelScanQRForReparkRestowing = () => {
    setScanQRForReparkRestowing(false);
    mixpanel.track('CANCEL_QR_TO_RESTOW', {session_id: parkingSession.id});
  }

  const handleCardAction = (payment_intent_client_secret) => {
    mixpanel.track('HANDLE_CARD_ACTION', {session_id: parkingSession.id});
    stripe
      .handleCardAction(payment_intent_client_secret)
      .then(function (result) {
        if (result.error) {
          setErrorMessage(result.error.message);
          setFailed(true);
          mixpanel.track('HANDLE_CARD_ACTION_FAILED', {session_id: parkingSession.id, err: result.error.message});
        } else {
          setFailed(false);
          // The card action has been handled
          // The PaymentIntent can be confirmed again on the server
          setShowSubmittingPayment(true);
          ParkingSessionService.retrieve(tokenForLeaving, parkingSession.id, paymentSummary)
            .then((res) => {
              setShowSubmittingPayment(false);
              const success = (res.success || res.payment.success);
              if (success) {
                updateSession(res.session);
              } else {
                // TODO: test if this can happen
                console.log('Payment failure', res);
              }
            }).catch((err) => {
            console.log('Error confirming payment 2nd time', err);
          });
        }
      }).catch((err) => {
      console.log('Error handling card action', err);
    });

  }

  const handleConfirmPayment = () => {
    setShowConfirmPayment(false);
    setShowSubmittingPayment(true);
    mixpanel.track('CONFIRM_PAYMENT', {session_id: parkingSession.id});
    ParkingSessionService.retrieve(tokenForLeaving, parkingSession.id, paymentSummary)
      .then((res) => {
        setShowSubmittingPayment(false);
        let success = (res?.success || res?.payment?.success);
        if (success) {
          updateSession(res.session);
        } else if (res?.totalAmount) {
          setSnackbarError('Payment amount changed, please re-approve');
          setPaymentSummary(res);
          setShowConfirmPayment(true);
        } else {
          handleCardAction(res.payment.payment_intent_client_secret);
        }
      }).catch((err) => {
        console.log('Error confirming payment', err);
        alert(err);
    })
  };

  const cancelConfirmPayment = () => {
    setShowConfirmPayment(false);
    mixpanel.track('CANCEL_CONFIRM_PAYMENT', {session_id: parkingSession.id});
  };

  /**
   * After retrieval, reload the user and reset some global state
   */
  const postRetrieveCleanup = async () => {
    setCarouselProgress(0);
    setRetrieveChecklistStep(0);
    setShowDriveAway(false);
  };

  const getRetrieveCardColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return activeColor;
    }
    return mutedColor;
  };

  const getDiscountButtonBgColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? successColor : 'white';
  };

  const getDiscountButtonTextColor = () => {
    return ParkingSessionService.isDiscountApplied(parkingSession) ? 'white' : activeColor;
  };

  const getRetrieveButtonBgColor = () => {
    if (failed) {
      return 'red';
    }
    if (ParkingSessionService.isParked(parkingSession)) {
      return '#FC9F0D';
    }
    return null;
  };

  const ScanQRComp = (isOpen, title, onScan, onCancel) => {
    return <ScanQRDialog classes={classes} isOpen={isOpen} title={title} onScan={onScan} onCancel={onCancel} />
  }

  const ShowParkingToken = (isOpen) => {
    return <ShowQRDialog classes={classes} isOpen={isOpen} title={'Hold QR code up to any reader'} code={parkingToken} onCancel={onCancelShowParkingToken} />
  }

  // Renderer callback with condition
  const SessionCountDownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <span>Canceling parking session</span>;
    } else {
      return <span>Remaining time: &#160;&#160;{minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span>;
    }
  };

  SessionCountDownRenderer.propTypes = {
    minutes: PropTypes.node.isRequired,
    seconds: PropTypes.node.isRequired,
    completed: PropTypes.node.isRequired
  };

  /**
   * Show a modal dialog box with optional carousel progress bar and/or button
   * @param isOpen whether or not to show the dialog
   * @param title the title of the dialog
   * @param text the text in the dialog
   * @param showCarouselProgress whether or not to show the carousel progress bar
   * @param timeoutSecs Session timeout to show Countdown
   * @param cancelTimeoutFn if timeoutSecs is defined, then this is a function to call for canceling the countdown (if desired)
   * @param cancelTimeoutText if timeoutSecs is defined, then this is the text to show in the cancel button (default 'Cancel')
   */
  const ModalText = (isOpen, title, text, showCarouselProgress = false, timeoutSecs, cancelTimeoutFn, cancelTimeoutText = 'Cancel') => {
    return <Dialog open={isOpen}
                   aria-labelledby='alert-dialog-title'
                   aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
      <DialogContent>
        <Typography component='h5' variant='h5'>
          {text}
        </Typography>
        {timeoutSecs &&
          <>
            <Typography component='h6' variant='h6' style={{marginTop: 20}}>
              <Countdown
                date={Date.now() + (Math.max(timeoutSecs, 0) * 1000)}
                renderer={SessionCountDownRenderer}
              />
            </Typography>
            {cancelTimeoutFn &&
              <Button style={{marginTop: 10}}
                onClick={() => {
                  confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Confirm'})
                    .then(() => cancelTimeoutFn())
                    .catch(() => {
                      // to suppress console logging on cancel
                    });
                }}
              >
                {cancelTimeoutText}
              </Button> }
          </>
        }
        {showCarouselProgress && <LinearProgress variant='determinate' value={carouselProgress} />}
      </DialogContent>
    </Dialog>
  }

  const SafetyChecklist = (isOpen, checklist, activeStep, nextStepFn, stowFn, stowText, cancelFn, cancelText) => {
    return <Dialog
      open={isOpen}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>Safety Check</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description' component='div'>
          <Stepper activeStep={activeStep} orientation='vertical'>
            {checklist.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
                <StepContent>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button
                        variant='contained'
                        color='primary'
                        onClick={nextStepFn}
                        className={classes.button}
                      >
                        {activeStep === checklist.length - 1
                          ? 'Finish'
                          : 'Next'}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            ))}
          </Stepper>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <div style={{textAlign: 'left'}}>
          {activeStep === checklist.length && (
            <>
              <Paper square elevation={0} style={{paddingBottom: 20}}>
                <Typography>Thank you for safety check.</Typography>
              </Paper>
              {stowFn &&
              <Button
                onClick={stowFn}
                color='primary'
                variant='contained'
                fullWidth
                className={classes.newButton}
              >
                {stowText}
              </Button> }
            </>
          )}
          {cancelFn &&
            <Button
              onClick={() => {
                confirm({ description: 'Are you sure you want to cancel?', confirmationText: 'Confirm'})
                  .then(() => cancelFn())
                  .catch(() => {
                    // to suppress console logging on cancel
                  });
              }}
            >
              {cancelText}
            </Button>
          }
        </div>
      </DialogActions>
    </Dialog>
  }

  const cancelSessionFn = async () => {
    const updatedSession = await ParkingSessionService.cancelSession(parkingSession?.id);
    if (updatedSession?.status) { // if an updated session was returned
      updateSession(updatedSession);
    }
  };

  return (
    <AuthenticatedContainer unAuthChildren={<HomeScreenWithoutLogin />}>
      <>
        <GridItem xs={12} sm={12} md={8} align='center'>

          {ShowParkingToken(isShowParkingToken)}

          {/* Parking */}
          {ModalText(!!ParkingSessionService.isQueuedToPark(parkingSession), 'Queued to Park', 'Please wait for your turn to enter', false, null, cancelSessionFn)}
          {ModalText(!!ParkingSessionService.isReadyToEnter(parkingSession), 'Ready to Enter', `Please drive onto ${parkingSession?.carousel?.nickname}`, false,
            parkingSession?.timeout_secs, cancelSessionFn, 'Cancel parking')}
          {SafetyChecklist((!!ParkingSessionService.isOnPallet(parkingSession) && !(scanQRForStowing || isShowParkingToken)), parkingChecklist, parkingChecklistStep, handleNextParkingChecklistStep, onScanQRForStowing, 'Scan QR Code to Stow Vehicle', cancelSessionFn, 'Cancel and Leave')}
          {ScanQRComp(scanQRForStowing, 'Scan QR Code to Stow Vehicle', handleQRScannedForStowing)}
          {ModalText((!!ParkingSessionService.isReadyToStow(parkingSession) || !!ParkingSessionService.isStowing(parkingSession)), 'Stowing Your Car', 'Your car is being stowed...', true)}

          {/* Reparking */}
          {ScanQRComp(readyToRepark, 'Scan QR Code to Start Retrieval', handleQRScannedForReparkRetrieval, cancelScanQRForReparkRetrieval)}
          {ModalText(!!ParkingSessionService.isQueuedToRepark(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isReparkingWaitingForCar(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, true)}
          {SafetyChecklist((!!ParkingSessionService.isReparkingCarAvailable(parkingSession) && !(scanQRForReparkRestowing || isShowParkingToken)), reparkChecklist, reparkChecklistStep, handleNextReparkChecklistStep, onScanQRForRestowing, 'Scan QR Code to Re-Stow Vehicle')}
          {ScanQRComp(scanQRForReparkRestowing, 'Scan QR Code to Re-Stow Your Vehicle', handleQRScannedForReparkRestowing, cancelScanQRForReparkRestowing)}

          {/* Retrieving */}
          {ScanQRComp(readyToLeave, 'Scan QR Code to Start Checkout', handleQRScannedForLeaving, cancelScanQRForLeaving)}
          {/* Confirm payment: see below */}
          {ModalText(showSubmittingPayment, 'Submitting Payment', 'Your payment is being submitted...')}
          {ModalText(!!ParkingSessionService.isQueuedToRetrieve(parkingSession), 'In the Queue', `Your vehicle is in the queue to retrieve at ${parkingSession?.carousel?.nickname}`)}
          {ModalText(!!ParkingSessionService.isRetrievingWaitingForCar(parkingSession), 'Retrieving Your Car', `Your vehicle is being retrieved at ${parkingSession?.carousel?.nickname}`, true)}
          {SafetyChecklist(showRetrieveChecklist, retrieveChecklist, retrieveChecklistStep, handleNextRetrieveChecklistStep)}
          {ModalText(showDriveAway, 'Thank You', 'Please drive off the carousel', false)}

          {/* Confirm payment */}
          <Dialog
            open={showConfirmPayment}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
            fullWidth
            maxWidth='sm'
          >
            <DialogTitle id='alert-dialog-title'>{'Confirm Payment'}</DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label='spanning table'>
                  <TableHead></TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Subtotal</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.totalAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Discount</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.discountAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Total</TableCell>
                      <TableCell align='right'>
                        {`$${paymentSummary?.chargedAmount?.toFixed(2)}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmPayment} color='primary' autoFocus>
                Confirm Payment
              </Button>
              <Button onClick={cancelConfirmPayment} color='secondary'>
                Cancel, I want to stay
              </Button>
            </DialogActions>
          </Dialog>


          {/* T&C dialog */}
          <Dialog
            open={showTAndC}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Terms and Conditions'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description' component='div'>
                <TermsOfUseContent />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseTAndC(false);
                }}
                color='primary'
              >
                Disagree
              </Button>
              <Button
                onClick={() => {
                  handleCloseTAndC(true);
                }}
                color='primary'
                autoFocus
              >
                Agree
              </Button>
              {saving && <CircularProgress size={14} className={classes.buttonProgress} />}
            </DialogActions>
          </Dialog>

          {/* Apply discount code dialog */}
          <Dialog
            open={showApplyDiscount}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Apply Promo Code'}</DialogTitle>
            <DialogContent>
              <TextField
                error={isDiscountCodeFieldError}
                autoFocus
                margin='dense'
                label='Code'
                value={discountCode}
                onChange={(e) => {
                  setDiscountCode(e.target.value);
                }}
                type='text'
                fullWidth
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCancelDiscount} color='primary'>
                Cancel
              </Button>
              <Button onClick={handleApplyDiscountCode} color='primary' autoFocus>
                Apply
              </Button>
            </DialogActions>
          </Dialog>

          <Box>
            <Typography component='h5' variant='h5' style={{ color: 'black', padding: 10 }}>
              What would you like to do?
            </Typography>
          </Box>
        </GridItem>

        {/* car image */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box p={5}>
            <img src={car} alt='Home header' width='100%' />
          </Box>
        </GridItem>
        <Locations />

        {/* PARK box */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box>
            <Card
              className={classes.root}
              elevation={6}
              style={{ height: '100%' }}
              onClick={handlePark}
            >
              <CardMedia
                className={classes.cover}
                style={{ backgroundColor: (ParkingSessionService.isParked(parkingSession) || userLocation?.availableSpaces === 0) ? mutedColor : mainColor }}
                title='Park'
              >
                <img src={parkIcon} alt='Park' style={ParkingSessionService.isParked(parkingSession) ? { height: '40%' } : {}} />
              </CardMedia>
              <CardContent className={classes.content}>
                <h4
                  className={ParkingSessionService.isParked(parkingSession) ? classes.smallCardText : classes.cardText}
                  style={{ color: ParkingSessionService.isParked(parkingSession) || userLocation?.availableSpaces === 0 ? mutedColor : mainColor }}
                >
                  {userLocation?.availableSpaces === 0 ? 'LOCATION FULL' : 'PARK'}
                </h4>
              </CardContent>
            </Card>
          </Box>
        </GridItem>

        {/* RETRIEVE box */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box style={{}}>
            <Card className={classes.root} style={{ height: '100%' }}>
              <CardMedia
                className={!ParkingSessionService.isParked(parkingSession) ? classes.cover : classes.coverParked}
                style={{
                  backgroundColor: getRetrieveCardColor(),
                }}
                title='retrieve'
              >
                <img
                  src={retrieveIcon}
                  alt='Retrieve'
                  style={!ParkingSessionService.isParked(parkingSession) ? { height: '40%' } : { height: '30px' }}
                />
              </CardMedia>
              <CardContent
                className={classes.content}
                style={
                  ParkingSessionService.isParked(parkingSession)
                    ? {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        width: '100%',
                      }
                    : {
                        width: '100%',
                      }
                }
              >
                <GridItem style={{ padding: '0px' }} align='left'>
                  <h4
                    style={{
                      color: ParkingSessionService.isParked(parkingSession) ? activeColor : mutedColor,
                      font: !ParkingSessionService.isParked(parkingSession)
                        ? 'normal normal 900 22px/30px Lato'
                        : 'normal normal 900 20px/25px Lato',
                    }}
                  >
                    RETRIEVE
                    {(ParkingSessionService.isParked(parkingSession) && (
                      <RefreshIcon
                        style={{ float: 'right' }}
                        onClick={() => {
                          handleRefresh();
                        }}
                      />
                    )) ||
                      null}
                  </h4>
                </GridItem>
                {(ParkingSessionService.isParked(parkingSession) && (
                  <>
                    <Divider />
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Parked Vehicle:</b> {parkingSession.vehicle_name}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 2 }}>
                      <b>Parked at:</b>{' '}
                      {moment(parkingSession.start_time).format('MM/DD/YYYY HH:mm:ss')}
                    </Typography>
                    <Typography variant='body1' align='left' style={{ paddingBottom: 5 }}>
                      <b>Park timer:</b> {parkTime}
                    </Typography>

                    { user && !user.is_free_parking &&
                      <>
                        <Button
                          variant='contained'
                          fullWidth={false}
                          style={{
                            color: getDiscountButtonTextColor(),
                            marginTop: 20,
                            lineHeight: '1.2em',
                            backgroundColor: getDiscountButtonBgColor(),
                          }}
                          onClick={handleEnterDiscountCode}
                        >
                          {ParkingSessionService.isDiscountApplied(parkingSession)
                            ? ParkingSessionService.getDiscountDescription(parkingSession)
                            : 'Enter discount code'}
                        </Button>
                      </>
                    }
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={false}
                      size='large'
                      style={{
                        color: 'white',
                        marginTop: 20,
                        lineHeight: '1.2em',
                        backgroundColor: getRetrieveButtonBgColor(),
                      }}
                      onClick={handleReadyToLeave}
                    >
                      READY TO LEAVE
                    </Button>
                    <Button
                      variant='contained'
                      fullWidth
                      disabled={reparkInProgress}
                      size='large'
                      style={{
                        color: 'darkslategray',
                        marginTop: 20,
                        lineHeight: '1.2em',
                        backgroundColor: 'lightyellow',
                      }}
                      onClick={handleGetSomethingFromVehicle}
                    >
                      Get something from vehicle
                    </Button>
                    <Paper square elevation={0}>
                      <Typography className={classes.smallNote}>You must be at the parking lot to scan a QR code in order to retrieve your vehicle for any reason.</Typography>
                    </Paper>
                    <Divider />
                    {failed && (
                      <Typography
                        variant='caption'
                        align='left'
                        style={{
                          paddingBottom: 10,
                          marginTop: 20,
                          color: 'red',
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    )}
                  </>
                )) ||
                  null}
              </CardContent>
            </Card>
          </Box>
        </GridItem>

        {/* needed? */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box>
            <h2 style={{ color: 'transparent' }}> - </h2>
          </Box>
        </GridItem>

        {/* snackbar */}
        <GridItem xs={12} sm={12} md={8} align='center'>
          <Box mt={5}>
            <Snackbar
              open={!!snackbarError}
              autoHideDuration={6000}
              onClose={() => {
                setSnackbarError(null);
              }}
            >
              <Alert
                onClose={() => {
                  setSnackbarError(null);
                }}
                severity='error'
              >
                {snackbarError}
              </Alert>
            </Snackbar>
          </Box>
        </GridItem>
      </>
    </AuthenticatedContainer>
  );
}
