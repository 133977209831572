import { useZxing } from 'react-zxing';
import PropTypes from "prop-types"

function QRScanner({ onScan }) {
  const { ref } = useZxing({
    onResult(result) {
      onScan(result.getText());
    },
  });

  return (
    <>
      <video width={'85%'} style={{display: 'block', margin: '0 auto', marginBottom: 10}} ref={ref} />
    </>
  );
}

QRScanner.propTypes = {
  onScan: PropTypes.func.isRequired,
};

export default QRScanner;
