import React from 'react';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import LocationService from '../../services/LocationService';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';

import useAPI from 'useAPI';
import { Context } from '../../Store';
import types from '../../Reducer/types';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  ...styles,
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  newButton: { marginTop: 20, color: 'white' },
}));

export const usePosition = () => {
  const [position, setPosition] = React.useState({});
  const [error, setError] = React.useState(null);

  const onChange = ({ coords }) => {
    setPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };
  const onError = (error) => {
    setError(error.message);
  };
  React.useEffect(() => {
    const geo = navigator.geolocation;
    if (!geo) {
      console.log('Geolocation not supported');
      setError('Geolocation is not supported');
      return;
    }
    let watcher = geo.watchPosition(onChange, onError);
    return () => geo.clearWatch(watcher);
  }, []);
  return { ...position, error };
};

function Location(props) {
  const { showMenu = true } = props;
  const classes = useStyles();
  const api = useAPI();
  const { state, dispatch } = React.useContext(Context);
  const { userLocation } = state;
  const { latitude, longitude } = usePosition();

  const [locationList, setLocationList] = React.useState([]);

  const handleChange = (val) => {
    const selectedLoc = locationList.filter((loc) => loc.id === val)[0];
    dispatch({
      type: types.SET_LOCATION,
      payload: selectedLoc,
    });
  };

  const fetchLocationList = async () => {
    const locations = await LocationService.getLocations();
    setLocationList(locations);
    if (locations.length === 1) {
      dispatch({
        type: types.SET_LOCATION,
        payload: locations[0],
      });
    } else if (!userLocation) {
      var closest = Infinity;
      var nearestLocationObj = null;
      for (const loc of locations) {
        var distance = LocationService.distance(latitude, longitude, loc.latitude, loc.longitude);
        if (distance <= closest) {
          closest = distance;
          nearestLocationObj = loc;
        }
      }
      if (nearestLocationObj) {
        dispatch({
          type: types.SET_LOCATION,
          payload: nearestLocationObj,
        });
      }
    }
  };

  React.useEffect(() => {
    LocationService.init(api);
    fetchLocationList();
  }, [latitude, longitude]);

  return (
    showMenu && (
      <GridItem xs={12} sm={12} md={8} align='center'>
        <FormControl className={classes.formControl}>
          <InputLabel id='demo-simple-select-outlined-label'>Location</InputLabel>
          <Select
            style={{ width: 300 }}
            label='Location'
            value={locationList && locationList.length > 0 ? (userLocation? userLocation.id : locationList[0].id) : ''}
            required
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            readOnly={locationList && locationList.length === 1}
            inputProps={
              locationList && locationList.length === 1 ? { IconComponent: () => null } : null
            } // hide arrow if read-only
          >
            <MenuItem
              disabled
              value={''}
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Choose Location
            </MenuItem>

            {locationList &&
              locationList.map((location) => (
                <MenuItem
                  value={location.id}
                  key={location.id}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                >
                  {location.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </GridItem>
    )
  );
}

Location.propTypes = {
  showMenu: PropTypes.bool,
};

export default Location;
