import React from 'react';

import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import AuthenticatedContainer from 'components/AuthenticatedContainer';
import { Typography, Button, IconButton } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useNavigate } from 'react-router-dom';
import CardService from '../../services/CardService';
import useAPI from 'useAPI';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { green } from '@mui/material/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import mixpanel from "mixpanel-browser"

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
    color: '#324152',
    marginTop: 20,
  },
  tabStyle: { color: '#324152', marginTop: 20 },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(10),
    right: theme.spacing(2),
  },
}));

function CardList() {
  let navigate = useNavigate();
  const classes = useStyles();
  const api = useAPI();
  const [cards, setCards] = React.useState([]);

  const fetchCards = async () => {
    const resposne = await CardService.getCards();
    setCards(resposne);
  };

  React.useEffect(() => {
    CardService.init(api);
    fetchCards();
    mixpanel.track('PAYMENT_METHODS');
  }, []);

  const addCard = () => {
    mixpanel.track('ADD_PAYMENT_OPTION');
    navigate('/payment-card/new');
  };
  const deleteCard = (card) => {
    if (!card.is_default) {
      CardService.deleteCard(card);
      const intervalRef = setTimeout(function () {
        fetchCards();
      }, 1000);
      return () => {
        clearTimeout(intervalRef);
      };
    }
  };
  const makeDefault = (card) => {
    if (!card.is_default) {
      CardService.setDefaultCard(card);
      const intervalRef = setTimeout(function () {
        fetchCards();
      }, 500);
      return () => {
        clearTimeout(intervalRef);
      };
    }
  };

  return (
    <AuthenticatedContainer>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography variant='h5' style={{ color: '#324152' }}>
          Select Default Card
        </Typography>
      </GridItem>
      {/* <GridItem align="right">
        <Button color="primary" variant="contained" onClick={() => addCard()}>
          Add Card
        </Button>
      </GridItem> */}
      <GridItem xs={12} sm={12} md={8} align='center'>
        <div className={classes.root}>
          <List>
            {cards &&
              cards.map((card) => (
                <ListItem
                  divider
                  key={card.id}
                  button
                  value={card}
                  onClick={() => makeDefault(card)}
                  style={{
                    backgroundColor: card.is_default ? green[500] : '#e0e3e7',
                    borderRadius: 5,
                    marginBottom: 10,
                  }}
                >
                  <ListItemIcon>
                    <CreditCardIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`**** **** **** ${card.number.slice(card.number.length - 5)}`}
                  />
                  <ListItemSecondaryAction>
                    {card.is_default ? (
                      <IconButton size='large'>
                        {' '}
                        <CheckCircleIcon style={{ color: '#006600' }} />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => deleteCard(card)} size='large'>
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
          </List>
        </div>
      </GridItem>
      {/* <Fab
        aria-label="Add"
        className={classes.fab}
        color="primary"
        onClick={() => addCard()}
      >
        <AddIcon />
      </Fab> */}
      <Button onClick={() => addCard()} variant='contained' color='primary' className={classes.fab}>
        Add Payment Option
      </Button>
    </AuthenticatedContainer>
  );
}

export default CardList;
