import React from 'react';
import './Policies.css'

const RemoteLink = (href, text) => {
  return <a target={'_blank'} rel={'noreferrer'} href={href}>{text || href}</a>
}
const EmailUs = <a href="mailto:info@stakmobility.com">info@stakmobility.com</a>;

export default function PrivacyPolicyContent() {
  return (
    <div className={'policy'}>
      <h1>Privacy Policy</h1>
      <p>Last Modified: February 2023</p>

      <div className={'policy-section'}>
        <h2>Introduction</h2>
        <p>Stak Corp. (<b>&quot;Company&quot;</b>, <b>&quot;we&quot;</b>, <b>&quot;our&quot;</b> or <b>&quot;us&quot;</b>)
          respects your privacy and are committed to protecting it through our compliance with this policy.</p>
        <p>This policy (the &quot;<b>Privacy Policy</b>&quot; or &quot;<b>policy</b>&quot;) describes the types of information we may collect from you or that you may provide when you visit or access the website {RemoteLink('https://www.stakmobility.com')}, including its microsites, mobile websites, mobile applications, and your use of interactive features, widgets, plug-ins, content, products, assets, downloads, and/or other services that we own or control or that post a link to our Terms of Use (collectively, the &quot;Website&quot;) (our &quot;<b>Website</b>&quot;) and our practices for collecting, using, maintaining, protecting and disclosing that information.</p>
        <p><b>PLEASE READ THIS PRIVACY POLICY CAREFULLY TO UNDERSTAND OUR POLICIES AND PRACTICES REGARDING YOUR INFORMATION AND HOW WE WILL TREAT IT.</b> If you do not agree with this Privacy Policy you must use our Website. By accessing or using this Website, you agree to this Privacy Policy. This policy is effective as of the date stated at the top of this page and may change from time to time, so please check this policy periodically for updates. Your continued use of this Website after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Children Under the Age of 13</h2>
        <p>Our Website is not intended for children under 13 years of age. No one under age 13 may provide any information to or on the Website. We do not knowingly collect personal information from children under 13. If you are under 13, do not use or provide any information on this Website or on or through any of its features/register on the Website, make any purchases through the Website, use any of the interactive or public comment features of this Website or provide any information about yourself to us, including your name, address, telephone number, e-mail address or any screen name or user name you may use. If we learn we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information. If you believe we might have any information from or about a child under 13, please contact us at {EmailUs}.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Information We Collect About You and How We Use It</h2>
        <p>This section describes the types of personal information we may collect about you. Such information is generally collected either (i) directly from your voluntary engagement with the Website; or (ii) automatically from you when you interact with the Website. We collect several types of information from and about users of our Website, including information:</p>
        <p><ul>
          <li><b>Contact information</b> &mdash; such as name, postal address, e-mail address, or telephone number.</li>
          <li><b>Feedback information</b> &mdash; to receive and respond to service request or comments on your experience with us, we may collect your name, phone number, address, email address, and any relevant history.</li>
          <li><b>Identification or Verification information</b> &mdash; to provide you with the services, we may collect your license plate number, driver’s license number, and other vehicle information such as a vehicle identification number (VIN) the color, or make or model of your vehicle.</li>
          <li><b>Financial information</b> &mdash; to process your payment for the services, we may collect your credit card number, billing address, bank account number, or other financial information to process payment. Note that Stak Corp does not itself collect that information. Instead, we pass that information on to our third-party payment processors.</li>
          <li><b>Location information</b> &mdash; to provide you with the services, we may automatically collect information from your device about your physical location.</li>
          <li><b>Device Information</b> &mdash; when you interact with our Website, we may automatically collect information about the computers, phones, and other devices you are using (e.g. IP address, WiFi information, internet connection, device attributes, operating system, browsing type, version , etc.).</li>
          <li><b>Website Usage</b> &mdash; so that we may understand and improve our user experience and functionality of the Website, we may automatically collect information about how you interact with the Website such as mouse movements, clicks, keystrokes, the date of your visit, the time spent on pages of the Website, or other actions.</li>
        </ul></p>
        <p>Information that is automatically collected may be obtained through cookies and similar technologies. Cookies are small files that are stored on your computer or mobile device through a user ID or other preferences. We use cookies to personalize your experience when you use the Website (e.g. auto-populating your log-in credentials or settings). You are free to set your browser or operating system settings to limit certain tracking or to decline cookies, but by doing so, you may not be able to use certain features through our Website or take full advantage of all our services. For more information about Cookies and Other Technology, please visit that section below.</p>
      </div>

      <div className={'policy-section'}>
        <h2>How We Share Information We Collect</h2>
        <p>We share personal information to provide the Website, including our services. Specifically, we limit sharing personal information to our employees, contractors, customers, agents, and service providers when there is a legitimate need to use it. For example, we share personal information with third-party service providers to offer customer support, detect fraud, process payments, and facilitate improvements and technical infrastructure to the Website and our services.</p>
        <p>We may also share your personal information in order to comply with applicable law or respond to a valid legal process or to enforce or investigate potential violations of our Terms of Use, SAAS Agreement, or other policies. If we are involved in a merger, acquisition, or sale of all or a portion of our assets, you will be notified via email and/or a prominent notice on our Website of any change in ownership or uses of personal information, as well as any choices you may have regarding your personal information.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Cookies and Other Tracking Technologies</h2>
        <p>We may automatically collect information about you when you interact with the Website by using the technologies described below:</p>
        <p>
          <ul>
            <li><b>Cookies (or browser cookies).</b> A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.</li>
            <li><b>Flash Cookies.</b> Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies.</li>
            <li><b>Web Beacons.</b> Pages of our the Website and our e-mails may contain small electronic files known as web beacons (also referred to as clear gifs. pixel tags and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an e-mail and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).</li>
          </ul>
        </p>
        <p>For more information about cookies and web beacons, please visit {RemoteLink('https://allaboutcookies.org')}.</p>
        <p>We currently do not honor Do Not Track signals from internet browsers. When a universal standard for processing them emerges, we will revisit it.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Security and Retention</h2>
        <p>We strive to use commercially acceptable means to protect personal information. Please be aware that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.</p>
        <p>We will keep your personal information as long as reasonably necessary to fulfill the purposes for which it was collected. In some circumstances we may retain your personal information for longer if and when required (e.g. legal, tax, or accounting requirements).</p>
      </div>

      <div className={'policy-section'}>
        <h2>Links to Other Sites</h2>
        <p>The Website may contain links to other sites for your convenience only. If you click on a third party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the applicable privacy policy of these third party websites. We have no control over, and assume no responsibility for the content, privacy policies or practices of any third party websites or services.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Choices About How We Use and Disclose Your Information</h2>
        <p>We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:</p>
        <p>
          <ul>
            <li><b>Tracking Technologies.</b> You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe&apos;s website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.</li>
            <li><b>Promotional Offers from the Company.</b> If you do not wish to have your email address used by the Company to promote our own or third parties&apos; products or services, you can opt-out by emailing us at {EmailUs}.</li>
          </ul>
        </p>
      </div>

      <div className={'policy-section'}>
        <h2>Accessing and Correcting Your Information</h2>
        <p>You may send us an e-mail at {EmailUs} to request access to, correct or delete any personal information that you have provided to us. We may be unable to delete your personal information except by also deleting your user account. We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
      </div>

      <div className={'policy-section'}>
        <h2>Contact Information</h2>
        <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: {EmailUs}.</p>
      </div>
    </div>
  );
}
