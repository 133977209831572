import React, { useState } from 'react';
import PropTypes from 'prop-types';

import styles from 'assets/jss/material-kit-react/views/landingPage.js';
import makeStyles from '@mui/styles/makeStyles';
// core components
import GridItem from 'components/Grid/GridItem.js';
import { Box, Typography, Button } from '@mui/material';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import VehicleService from '../../services/VehicleService';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import WarningIcon from '@mui/icons-material/Warning';
import { useNavigate } from 'react-router-dom';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Context } from 'Store';
import FormattedInput from 'components/Pattern/FormattedInput';
import useAPI from 'useAPI';
import { CheckRounded } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { default as reducerTypes } from '../../Reducer/types';
import mixpanel from "mixpanel-browser"

const useStyles = makeStyles((theme) => ({
  ...styles,
  customTextField: {
    position: 'relative',
    width: '100%',
    minHeight: '1px',
    paddingRight: '15px',
    paddingLeft: '15px',
    flexBasis: 'auto',
  },
  inputLabel: {
    color: '#008BE8',
    font: 'normal normal bold 16px/19px Lato',
  },
  float: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 20,
    bottom: 20,
    left: 'auto',
    position: 'fixed',
  },
  floatPin: {
    margin: theme.spacing(1),
    top: 'auto',
    right: 'auto',
    bottom: 30,
    left: 20,
    position: 'fixed',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: 'relative',
    overflow: 'auto',
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: theme.palette.background,
  },
  ul: {
    backgroundColor: theme.palette.background,
    padding: 0,
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

function VehicleForm({ id = null }) {
  let navigate = useNavigate();
  const classes = useStyles();
  const { dispatch } = React.useContext(Context);
  const api = useAPI();
  const [saving, setSaving] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [infoModal, setInfoModal] = React.useState(false);
  const [vehicleMakes, setVehicleMakes] = React.useState(['']);
  const [vehicleModels, setVehicleModels] = React.useState(['']);
  const states = require('./States');
  const color = require('./Colors');

  const initialValues = {
    make: '',
    model: '',
    year: '',
    state: '',
    license_state: '',
    license_number: '',
    plate: '',
    description: '',
    color: '',
    zip: '',
    is_default: false,
  };
  const [oldValues, setOldValues] = React.useState(initialValues);
  const [values, setValues] = React.useState(initialValues);
  const [errors, setErrors] = React.useState({
    make: '',
    model: '',
    year: '',
    state: '',
    license_state: '',
    license_number: '',
    plate: '',
    description: '',
    color: '',
    zip: '',
    is_default: '',
  });
  const [vehicleLabel, setvehicleLabel] = React.useState({
    label: 'You have unsaved changes',
    success: false,
    color: '#FC9F0D',
  });
  const [isFromPark, setIsFromPark] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    if (location.pathname.includes('park')) {
      setIsFromPark(true);
    }
  }, []);

  React.useEffect(() => {
    VehicleService.init(api);
    if (id) {
      fetchUserVehicle();
    }
  }, [api]);

  React.useEffect(() => {
    if (
      JSON.stringify(oldValues) !== JSON.stringify(values) &&
      JSON.stringify(oldValues) !== JSON.stringify(initialValues)
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [values]);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
    if (verifyLength(event.target.value, 1)) {
      setErrors({ ...errors, [prop]: 'success' });
    } else {
      setErrors({ ...errors, [prop]: 'error' });
    }
  };

  const fetchVehicleModels = async (makeId) => {
    await VehicleService.getVehicleModel(makeId).then((res) => {
      if (res) {
        setVehicleModels(res);
      }
    });
  };
  const handleDefault = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.checked });
  };

  const handleSave = () => {
    mixpanel.track('SAVE_VEHICLE');
    saveVehicle();
  };

  const verifyLength = (value, length) => {
    return value.length >= length;
  };

  const saveVehicle = async () => {
    let error = 0;
    let newErrors = errors;
    ['make', 'plate', 'color', 'zip'].forEach((field) => {
      if (values[field] === '') {
        newErrors[field] = 'error';
        error = 1;
      }
    });
    setErrors({ ...newErrors });

    if (error) return;
    setSaving(true);
    setvehicleLabel({
      label: 'Changes saved!',
      success: true,
      color: 'green',
    });
    setTimeout(() => {
      setvehicleLabel({
        label: 'You have unsaved changes',
        success: false,
        color: '#FC9F0D',
      });
    }, 5000);
    await VehicleService.storeVehicle({
      id: values.id,
      user_id: values.user_id,
      color: values.color,
      zip: values.zip,
      make: values.make,
      model: values.model,
      year: values.year,
      state: values.state,
      license_state: values.license_state,
      license_number: values.license_number,
      plate: values.plate,
      description: values.description,
      is_default: values.is_default ? 1 : 0,
      hasSubscriptionBooking: false,
    });

    // Refresh vehicle list in state
    const allVehicles = await VehicleService.getVehicles();
    dispatch({ type: reducerTypes.SET_VEHICLES, payload: allVehicles });

    if (id) {
      fetchUserVehicle();
    }
    setSaving(false);
    if (isFromPark) {
      setTimeout(() => {
        navigate('/park/vehicle/select');
      }, 1000);
    } else {
      setTimeout(() => {
        navigate('/vehicles/manage');
      }, 1000);
    }
  };

  const fetchUserVehicle = async () => {
    const res = await VehicleService.getVehicleById(id);
    if (res) {
      setValues(res);
      setOldValues(res);
      await fetchVehicleMakes(res.make);
    }
  };

  const fetchVehicleMakes = async (search) => {
    const res = await VehicleService.getVehicleMakes(search);
    if (res) {
      setVehicleMakes(res);
      const matchedMake = res.find((m) => m.name === search);
      if (matchedMake) {
        fetchVehicleModels(matchedMake.id);
      }
    }
  };

  return (
    <>
      <GridItem xs={12} sm={12} md={8} align='center'>
        {(vehicleLabel.success || isChanged) &&
        <Typography
          variant='body1'
          style={{
            padding: 15,
            color: `${vehicleLabel.color}`,
          }}
        >
          <>
            {(vehicleLabel.success && <CheckRounded />) || <WarningIcon />}
            {vehicleLabel.label}
          </>
        </Typography> }
      </GridItem>
      <GridItem xs={12} sm={12} md={8} align='center'>
        <Typography
          variant='subtitle1'
          style={{
            paddingTop: 15,
            paddingBottom: 15,
            color: 'black',
            lineHeight: '1.5em',
          }}
        >
          Ensure vehicle meets these size limits:
          <br/>
          Max length = 17 ft.
          <br/>
          Max width = 6 ft. 10 in.
          <br/>
          Max height = 6 ft. 2 in.
          <br/>
          Max weight = 5,291 lb.
        </Typography>
      </GridItem>
      <GridItem xs={12} sm={12} md={8}>
        <Box pb={5} className={classes.inputLabel}>
          <Grid
            item
            container
            direction='row'
            alignItems='center'
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Make:
            </Grid>
            <Grid item xs={9} sm={9}>
              <Autocomplete
                id='make'
                freeSolo
                filterOptions={(x) => x}
                fullWidth
                margin='dense'
                options={vehicleMakes}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || option.name === value.name
                }
                value={values.make}
                inputValue={values.make}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setValues({
                      ...values,
                      make: newValue.name,
                    });
                    fetchVehicleModels(newValue.id);
                  } else {
                    setValues({
                      ...values,
                      make: '',
                    });
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) {
                    setValues({
                      ...values,
                      make: newInputValue,
                    });
                    fetchVehicleMakes(newInputValue);
                  }
                }}
                noOptionsText='No Matching Make'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Vehicle Manufacturer (ex. Honda, BMW)'
                    margin='dense'
                    error={errors.make === 'error'}
                    autoFocus={true}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Model:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <Autocomplete
                id='model'
                freeSolo
                filterOptions={(x) => x}
                fullWidth
                margin='dense'
                options={vehicleModels}
                getOptionLabel={(option) => (option.name ? option.name : '')}
                isOptionEqualToValue={(option, value) =>
                  option.name === value || option.name === value.name
                }
                value={values.model}
                inputValue={values.model}
                onChange={(event, newValue) => {
                  setValues({
                    ...values,
                    model: newValue ? newValue.name : '',
                  });
                }}
                onInputChange={(event, newInputValue) => {
                  if (newInputValue) {
                    setValues({
                      ...values,
                      model: newInputValue,
                    });
                  }
                }}
                noOptionsText='No Matching Make'
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder='Vehicle Model Name (ex. Accord, 335i)'
                    margin='dense'
                    error={errors.model === 'error'}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Plate #:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <TextField
                error={errors.plate === 'error'}
                margin='dense'
                id='plate'
                value={values.plate}
                type='text'
                fullWidth
                onChange={handleChange('plate')}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Color:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth margin='dense'>
                <Select
                  value={values.color}
                  onChange={handleChange('color')}
                  error={errors.color === 'error'}
                  style={{ textAlign: 'left' }}
                >
                  {color.map((color) => (
                    <MenuItem key={`${color.name}`} value={`${color.name}`}>
                      {`${color.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              Zip:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormattedInput
                name='zip'
                format='#####'
                mask=' '
                label='Zip Code'
                value={values.zip}
                onChange={handleChange('zip')}
              />
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={3} sm={3}>
              State / Province:
            </Grid>
            <Grid item xs={9} sm={9} md={9}>
              <FormControl fullWidth margin='dense'>
                <Select
                  value={values.state}
                  onChange={handleChange('state')}
                  error={errors.state === 'error'}
                  style={{ textAlign: 'left' }}
                >
                  {states.map((state) => (
                    <MenuItem key={`${state}`} value={`${state}`}>
                      {`${state}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid
            container
            direction='row'
            alignItems='center'
            item
            xs={12}
            sm={12}
            md={12}
            align='center'
            spacing={0}
          >
            <Grid item xs={9} sm={9} md={9}>
              <FormControlLabel
                control={
                  <Checkbox checked={!!values.is_default} onChange={handleDefault('is_default')} />
                }
                label='Set as default vehicle'
              />
            </Grid>
          </Grid>

          <Button
            onClick={handleSave}
            color='primary'
            variant='contained'
            fullWidth
            style={{
              marginTop: 20,
              color: 'white',
              background: `${vehicleLabel.color}`,
            }}
          >
            {isFromPark ? 'Save & Park' : 'Save Changes'}
          </Button>
          {saving && <CircularProgress size={24} className={classes.buttonProgress} />}

          <Dialog
            open={infoModal}
            onClose={() => setInfoModal(false)}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>{'Info'}</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {/* {modalBody} */}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setInfoModal(false)} color='primary'>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </GridItem>
    </>
  );
}

VehicleForm.propTypes = {
  id: PropTypes.string,
};

VehicleForm.defaultProps = {
  id: null,
};

export default VehicleForm;
