import Axios from 'axios';

let api = Axios;

const init = async (axiosApi) => {
  api = axiosApi;
};

const getVehicles = async () => {
  try {
    const response = await api.get('/vehicles');
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleMakes = async (search) => {
  try {
    const response = await api.get('/vehicles/makes/' + search);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleById = async (id) => {
  try {
    const response = await api.get('/vehicles/' + id);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const getVehicleModel = async (id) => {
  try {
    const response = await api.get('/vehicles/models/' + id);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const storeVehicle = async (data) => {
  try {
    const response = await api.put('/vehicles', data);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

const VehicleService = {
  init,
  getVehicles,
  getVehicleById,
  storeVehicle,
  getVehicleMakes,
  getVehicleModel,
};

export default VehicleService;
